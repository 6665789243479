import React from 'react'
import { Modal } from 'react-bootstrap'
import * as $Opportunity from '@services/Opportunity'
import Cnpj from '@components/Form/Cnpj/Cnpj'
import { useMutation } from 'react-query'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

type Props = {
  show: boolean
  onHide: () => void
}

const Opportunity: React.FC<Props> = ({ show, onHide }: Props) => {
  const create = useMutation($Opportunity.create)

  const SweetAlert = withReactContent(Swal)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    SweetAlert.fire({
      title: 'Registrando oportunidade',
      text: 'Aguarde enquanto a oportunidade é registrada...',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    const formData = new FormData(event.currentTarget)

    const cnpj = formData.get('cnpj')!.toString().replace(/\D/g, '')

    formData.set('cnpj', cnpj)

    create.mutate(formData, {
      onSuccess: () => {
        SweetAlert.fire({
          title: 'Oportunidade registrada',
          text: 'A oportunidade foi registrada com sucesso.',
        }).then(onHide)
      },
      onError: (e: any) => {
        if (e.response.data?.errors?.length > 0) {
          const html = Object.values(e.response.data.errors).map((value: any) => {
            return value.map((message: string) => `<li>${message}</li>`).join('')
          }).join('')

          SweetAlert.fire({
            title: 'Ocorreu um erro, verifique os campos abaixo e tente novamente.',
            html: `<ul>${html}</ul>`,
            icon: 'error',
          })
        } else if (e.response.data?.error?.length > 0) {
          SweetAlert.fire({
            title: e.response.data.error,
            text: e.response.data.message,
            icon: 'error',
          }).then(onHide)
        } else {
          SweetAlert.fire({
            title: 'Ocorreu um erro, tente novamente.',
            icon: 'error',
          })
        }
      }
    })
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Registrar Oportunidade</Modal.Title>
      </Modal.Header>

      <div style={{ borderRadius: 0 }} className="alert alert-info">
        Registro de oportunidade estabelece algumas regras para a comercialização da plataforma <strong>Loqed Systems®</strong> pelos parceiros devidamente cadastrados.
        <br/><br/>
        <strong>O que é o registro de oportunidade?</strong> É o registro que o parceiro comercial faz, indicando que ele está trabalhando com aquele determinado cliente final.
        Isso impede que outros parceiros possam interagir com esse cliente final e, caso o façam, não terão a autorização da <strong>Loqed Systems®</strong> para vender as licenças ou fornecer o serviço.
        A <strong>Loqed Systems®</strong> também reserva o direito de oferecer valores e condições diferenciadas para clientes fechados mediante registro de oportunidade, conforme avaliação interna
        da equipe comercial.
        <br/><br/>
        <strong>Qual é o prazo do registro e por quanto tempo um cliente fica vinculado a um parceiro comercial?</strong> A princípio, um registro de oportunidade <u>APROVADO</u> pela <strong>Loqed Systems®</strong> tem
        a duração de 90 dias. Após esse período, o parceiro pode tentar a renovação por mais 90 dias, justificando o andamento até aquele
        momento. Essa solicitação será avaliada pela equipe comercial da <strong>Loqed Systems®</strong>, que reserva o direito de aprovar ou negar a renovação do registro desse
        cliente final, podendo então ser transferido para ser trabalhado por outro parceiro comercial.
      </div>

      <form onSubmit={onSubmit}>
        <Modal.Body className="pb-0">
          <div className="row">
            <div className="col-12 col-md-6 form-group mb-3">
              <label htmlFor="company_name">Razão Social:</label>
              <input type="text" name="company_name" id="company_name" className="form-control" required />
            </div>

            <div className="col-12 col-md-6 form-group mb-3">
              <label htmlFor="trading_name">Nome Fantasia:</label>
              <input type="text" name="trading_name" id="trading_name" className="form-control" required />
            </div>

            <div className="col-12 col-md-6 form-group mb-3">
              <label htmlFor="cnpj">CNPJ:</label>
              <Cnpj type="text" name="cnpj" id="cnpj" className="form-control" required />
            </div>

            <div className="col-12 col-md-6 form-group mb-3">
              <label htmlFor="computers_number">Número de Máquinas:</label>
              <input type="number" name="computers_number" id="computers_number" className="form-control" required />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">Registrar Oportunidade</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Opportunity
