import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Nav } from './Sidebar.styles'
import PanelContext from '@contexts/Panel'

const Sidebar: React.FC<any> = () => {
  const { activeMenu } = useContext(PanelContext)

  return (
    <nav className="main-menu">
      <Nav className="nav flex-column">
        <li className="nav-header">Conteúdo</li>

        <li className={'nav-item' + (activeMenu === '/avisos' || activeMenu === '/' ? ' active' : '')}>
          <Link to="/avisos" className="nav-link">
            <span>Avisos</span>
          </Link>
        </li>

        <li className={'nav-item' + (activeMenu === '/documentos' ? ' active' : '')}>
          <Link to="/documentos" className="nav-link">
            <span>Documentos</span>
          </Link>
        </li>

        <li className={'nav-item mb-3' + (activeMenu === '/oportunidades' ? ' active' : '')}>
          <Link to="/oportunidades" className="nav-link">
            <span>Registro de Oportunidades</span>
          </Link>
        </li>

        <li className="nav-header">Gerenciamento</li>

        <li className={'nav-item' + (activeMenu === '/usuarios' ? ' active' : '')}>
          <Link to="/usuarios" className="nav-link">
            <span>Usuários</span>
          </Link>
        </li>
      </Nav>
    </nav>
  )
}

export default Sidebar
