import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as $Auth from '@services/Auth'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Cnpj from '@components/Form/Cnpj/Cnpj'
import Phone from '@components/Form/Phone/Phone'
import { Auth } from '../Login/Login.styles'
import { Spinner } from 'react-bootstrap'

const Register: React.FC<any> = () => {
  const [ isLoading, setIsLoading ] = useState(false)

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)
  const formRef = useRef<HTMLFormElement>(null)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    const formData = new FormData(e.target as HTMLFormElement)

    formData.set('phone',
      (formData.get('phone') as string).replace(/\D/g, ''))

    formData.set('cnpj',
      (formData.get('cnpj') as string).replace(/\D/g, ''))

    SweetAlert.fire({
      title: 'Aguarde',
      text: 'Estamos realizando sua solicitação de cadastro...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Auth.register(formData).then(() => {
      formRef.current?.reset()
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Sua solicitação de cadastro foi realizada com sucesso. Aguarde a aprovação de um administrador.',
        icon: 'success',
      })
    }).catch(e => {
      if (e.response?.data?.errors) {
        const errors = e.response.data.errors

        const html = Object.values(errors).map((value: any) => {
          return value.map((message: string) => `<li>${message}</li>`).join('')
        }).join('')

        SweetAlert.fire({
          title: 'Erro!',
          icon: 'error',
          confirmButtonText: 'Ok',
          html,
        })
      } else {
        SweetAlert.fire({
          title: 'Erro!',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao realizar sua solicitação de cadastro. Tente novamente mais tarde.',
          icon: 'error',
        })
      }
    }).finally(() => setIsLoading(false))
  }

  return (
    <div className="row">
      <div className="col-12 col-md-6 offset-md-3">
        <div className="card">
          <div className="card-header">
            <h1 className="w-100 d-block text-center mb-3">Solicitação de cadastro</h1>

            <div className="alert alert-info mb-0">
              SE VOCÊ NÃO FOR O GESTOR DO CONTRATO, SOLICITE A ELE SEU CADASTRAMENTO. Todos os cadastros passarão por aprovação interna da Loqed Systems®;
            </div>
          </div>

          <div className="card-body">
            <Auth ref={formRef} onSubmit={onSubmit}>
              <div className="row">
                <div className="col col-12">
                  <div className="form-floating mb-3">
                    <input type="text" name="company_name" id="company_name" className="form-control" placeholder="Razão social" required />
                    <label htmlFor="company_name">Razão social</label>
                  </div>
                </div>

                <div className="col col-12 col-md-6">
                  <div className="form-floating mb-3">
                    <Cnpj name="cnpj" id="cnpj" className="form-control" placeholder="CNPJ" required />
                    <label htmlFor="cnpj">
                      CNPJ
                    </label>
                  </div>
                </div>

                <div className="col col-12 col-md-6">
                  <div className="form-floating mb-3">
                    <input type="text" name="name" id="name" className="form-control" placeholder="Nome completo" required />
                    <label htmlFor="name">Nome completo</label>
                  </div>
                </div>

                <div className="col col-12 col-md-6">
                  <div className="form-floating mb-3">
                    <input type="email" name="email" id="email" className="form-control" placeholder="name@example.com" required />
                    <label htmlFor="email">Seu endereço de e-mail</label>
                  </div>
                </div>

                <div className="col col-12 col-md-6">
                  <div className="form-floating mb-3">
                    <Phone type="text" name="phone" id="phone" className="form-control" placeholder="Telefone" required />
                    <label htmlFor="phone">
                      Telefone <i className="form-text">(Com DDD)</i>
                    </label>
                  </div>
                </div>

                <div className="col col-12 col-md-6">
                  <div className="form-floating mb-3">
                    <input type="password" name="password" id="password" className="form-control" placeholder="Senha" required />
                    <label htmlFor="password">
                      Senha <i className="form-text">(mínimo de 6 caracteres)</i>
                    </label>
                  </div>
                </div>

                <div className="col col-12 col-md-6">
                  <div className="form-floating mb-3">
                    <input type="password" name="password_confirmation" id="password_confirmation" className="form-control" placeholder="Confirme sua senha" required />
                    <label htmlFor="password_confirmation">
                      Confirme sua senha
                    </label>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block w-100" disabled={isLoading}>
                Criar conta {isLoading && <Spinner animation="border" size="sm" className="me-2" />}
              </button>

              <hr/>

              <div className="login">
                <h5>Já possui uma conta?</h5>
                <Link to="/login">Efetue o login</Link>
              </div>
            </Auth>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
