import React from 'react'
import IOpportunity from '@interfaces/IOpportunity'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Opportunity from '@services/Opportunity'
import { useMutation } from 'react-query'
import { Modal } from 'react-bootstrap'

type Props = {
  opportunity: IOpportunity|null
  show: boolean
  onHide: () => void
}

const Action: React.FC<any> = ({ opportunity, show, onHide }: Props) => {
  const SweetAlert = withReactContent(Swal)

  const renewal = useMutation($Opportunity.renewal)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!opportunity)
      return

    const formData = new FormData(event.currentTarget)

    SweetAlert.fire({
      title: 'Solicitando renovação',
      text: 'Aguarde enquanto a renovação é solicitada...',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    renewal.mutate([opportunity.id, formData], {
      onSuccess: () => {
        SweetAlert.fire({
          title: 'Renovação solicitada',
          text: 'A renovação foi solicitada com sucesso.',
        }).then(onHide)
      },
      onError: () => {
        SweetAlert.fire({
          title: 'Erro ao solicitar renovação',
          text: 'Ocorreu um erro ao solicitar a renovação, tente novamente.',
          icon: 'error',
        })
      },
    })
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Renovar Oportunidade #{opportunity?.id}</Modal.Title>
      </Modal.Header>

      <form onSubmit={onSubmit}>
        <Modal.Body className="pb-0">
          <div className="form-group mb-3">
            <label htmlFor="renewal_description">Justifique seu pedido de renovação:</label>
            <textarea name="renewal_description" id="renewal_description" className="form-control" rows={5} required />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary btn-sm pe-2 ps-2">Renovar</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Action
