import React from 'react'
import { all } from '@services/Note'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import Note from './Note/Note'

const Notes: React.FC<any> = () => {
  const { data: response } = useQuery('admins', all)

  const notes = response?.data || []

  return (
    <>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1 className="page-title mb-0">Avisos</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Início</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Avisos</li>
            </ol>
          </nav>
        </div>
      </div>

      {notes.length > 0 ? notes.map((note: any) => (
        <Note {...note} key={note.id} />
      )) : (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      )}
    </>
  )
}

export default Notes
