import axios from 'axios'

export const securityCode = (email: string, password: string) => axios.post('v1/client/auth/security-code', {
  email,
  password,
})

export const login = (email: string, password: string, securityCode: string): Promise<any> => axios.post('v1/client/auth/login', {
  email,
  password,
  security_code: securityCode,
})

export const me = (): Promise<any> => axios.get('v1/client/auth/me')

export const forgotPassword = (email: string): Promise<Response> => axios.post('v1/client/auth/forgot-password', {
  email,
})

export const resetPassword = (token: string, email: string, password: string, passwordConfirmation: string): Promise<Response> => axios.post('v1/client/auth/reset-password', {
  token,
  email,
  password,
  password_confirmation: passwordConfirmation,
})

export const register = (formData: FormData) => axios.post('v1/client/auth/register', formData)
