import React, { useState, useEffect } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { Categories, Panel } from './Document.styles'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { all } from '@services/DocumentCategory'

const Documents: React.FC<any> = () => {
  const [ activeKey, setActiveKey ] = useState<string|number|undefined>()

  const { data: response } = useQuery('categories', all)

  useEffect(() => {
    if (response && response.data.length > 0 && !activeKey) {
      setActiveKey(response.data[0].id)
    }
  }, [activeKey, response])

  const categories = response?.data || []

  const onSelect = (key: string|number|null|undefined) => setActiveKey(key!)

  return (
    <>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1 className="page-title mb-0">Documentos</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Início</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Documentos</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <Tab.Container activeKey={activeKey} onSelect={onSelect}>
            <div className="row align-content-stretch">
              <div className="col-12 col-md-2">
                <Categories className="card">
                  <div className="card-body">
                    <Nav variant="pills" className="flex-column">
                      {categories.map(category => (
                        <Nav.Item key={`nav-${category.id}`}>
                          <Nav.Link eventKey={category.id}>
                            <span>{category.name}</span>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                </Categories>
              </div>

              <Panel className="col-12 col-md-10">
                <Tab.Content>
                  {categories.map(category => (
                    <Tab.Pane eventKey={category.id} key={`pane-${category.id}`}>
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-0">{category.name}</h5>
                          <small className="text-muted">
                            {category.documents.length} documento{category.documents.length === 1 ? '' : 's'}
                          </small>
                        </div>

                        <div className="card-body p-0">
                          {category.documents.length > 0 ? (
                            <div className="table-responsive">
                              <table className="table table-hover mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th>Arquivo</th>
                                    <th>Data do recebimento</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {category.documents.map(document => (
                                    <tr key={document.id}>
                                      <td>
                                        <div className="name">
                                          <a href={document.file as string} target="_blank" rel="noreferrer" download>{document.name}</a>
                                        </div>
                                      </td>

                                      <td style={{ width: 200 }}>
                                        {moment(document.created_at).format('DD/MM/YYYY [ás] HH:mm')}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className="alert alert-secondary mb-0 p-2 pe-3 ps-3 m-3">Nenhum documento encontrado nesta categoria.</div>
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Panel>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  )
}

export default Documents
