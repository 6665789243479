import React, { useMemo, useContext } from 'react'
import { Link } from 'react-router-dom'
import { BsTrash } from 'react-icons/bs'
import { AiOutlineSearch } from 'react-icons/ai'
import Mask from '@helpers/Mask'
import AuthContext from '@contexts/Auth'

const useUserColumns = () => {
  const { user } = useContext(AuthContext)

  return useMemo(() => {
    const columns = [
      {
        Header: '#',
        disableSortBy: false,
        Cell: ({ row: { original: user } }: any) => (
          <Link to={`/usuarios/${user.id}`} id="modal-edit-contact" className="trigger--fire-oe">
            <AiOutlineSearch size={20} />
          </Link>
        ),
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ row: { original: user } }: any) => (
          <Link to={`/usuarios/${user.id}`} id="modal-edit-contact" className="trigger--fire-oe">
            {user.id}
          </Link>
        ),
      },
      {
        Header: 'Nome',
        accessor: 'name',
        Cell: ({ row: { original: user } }: any) => (
          <Link to={`/usuarios/${user.id}`} id="modal-edit-contact" className="trigger--fire-oe">
            {user.name}
          </Link>
        ),
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
        Cell: ({ row: { original: user } }: any) => Mask.phone(user.phone || '', true),
      },
    ]

    if (user?.is_owner) {
      columns.push({
        Header: 'Ações',
        disableSortBy: false,
        Cell: ({ row: { original: { is_owner } } }: any) => (
          <button className={'btn btn-sm pe-2 ps-2 btn-' + (is_owner ? 'secondary' : 'danger')} disabled={is_owner}>
            <BsTrash size={20} />
          </button>
        ),
        style: {
          width: 100,
          textAlign: 'right',
        },
      } as any)
    }

    return columns
  }, [user])
}

export default useUserColumns
