import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import IUser from '@interfaces/IUser'
import { useMutation, useQuery } from 'react-query'
import { find, create, update } from '@services/User'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Phone from '@components/Form/Phone/Phone'
import Mask from '@helpers/Mask'
import AuthContext from '@contexts/Auth'

const User: React.FC<any> = () => {
  const { user: me } = useContext(AuthContext)

  const [ user, setUser ] = useState<IUser|null>(null)

  const { id }: any = useParams()
  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)
  const passwordRef = useRef<HTMLInputElement>(null)
  const passwordConfirmationRef = useRef<HTMLInputElement>(null)

  const { isLoading, data: response, error } = useQuery(id, find, {
    enabled: Boolean(id),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  })

  const hasPrivilege = useMemo(() => me?.is_owner || user?.id === me?.id, [me, user])

  useEffect(() => {
    if (!isLoading && !error && response) {
      setUser(response.data)
    }
  }, [isLoading, error, response, setUser])

  const { mutate } = useMutation((data: IUser) => {
    if (data.id > 0) {
      return update(data)
    } else {
      return create(data)
    }
  })

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => setUser({
    ...user,
    [name]: value,
  } as IUser)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!user || !hasPrivilege)
      return

    SweetAlert.fire({
      title: id ? 'Atualizando usuário' : 'Registrando usuário',
      text: 'Aguarde enquanto o usuário é ' + (id ? 'atualizado' : 'registrado') + '...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    mutate(user, {
      onSuccess: ({ data }) => {
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Administrador ' + (id ? 'atualizado' : 'registrado') + ' com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        }).then(() => navigate(`/usuarios/${data.id}`))

        if (passwordRef?.current !== null && passwordConfirmationRef?.current !== null) {
          passwordRef.current.value = ''
          passwordConfirmationRef.current.value = ''
        }
      },
      onError: (e: any) => {
        if (e.response?.data?.errors) {
          const errors = e.response.data.errors

          const html = Object.values(errors).map((value: any) => {
            return value.map((message: string) => `<li>${message}</li>`).join('')
          }).join('')

          SweetAlert.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro ao ' + (id ? 'atualizar' : 'registrar') + ' o usuário!',
            icon: 'error',
            confirmButtonText: 'Ok',
            html,
          })
        } else {
          SweetAlert.fire({
            title: 'Erro!',
            text: e.response?.data?.message ?? 'Ocorreu um erro ao ' + (id ? 'atualizar' : 'registrar') + ' o usuário!',
            icon: 'error',
          })
        }
      },
    })
  }

  return (
    <>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h1 className="page-title mb-0">
          {id ? user?.name : (
            <>
              {user?.name && user.name.length > 0 ? `Registrar usuário: ${user.name}` : 'Registrar usuário'}
            </>
          )}
        </h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Início</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/usuarios">Usuários</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {id ? user?.name : 'Registrar usuário'}
            </li>
          </ol>
        </nav>
      </div>

      <form onSubmit={onSubmit}>
        <div className="card mb-3">
          <div className="card-header">
            <h3 className="card-title mb-0">Dados do usuário</h3>
          </div>

          <div className="card-body pb-0">
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label htmlFor="name">Nome:</label>
                  <input type="text" name="name" id="name" className="form-control" defaultValue={user?.name} onChange={handleInputChange} required />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="email">E-mail:</label>
                  <input type="email" name="email" id="email" className="form-control" defaultValue={user?.email} onChange={handleInputChange} required />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="phone">Telefone:</label>
                  <Phone type="text" name="phone" id="phone" className="form-control" defaultValue={user?.phone ? Mask.phone(user.phone, true) : ''} onChange={handleInputChange} />
                </div>
              </div>

              {!id && (
                <>
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="password">Senha:</label>
                      <input type="password" name="password" id="password" className="form-control" onChange={handleInputChange} />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="password_confirmation">Confirmação de senha:</label>
                      <input type="password" name="password_confirmation" id="password_confirmation" className="form-control" onChange={handleInputChange} />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {hasPrivilege && (
            <div className="card-footer d-flex justify-content-end">
              <button type="submit" className="btn btn-primary pe-3 ps-3 btn-sm">
                {user?.id ? 'Atualizar' : 'Salvar'}
              </button>
            </div>
          )}
        </div>

        {id > 0 && (
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Alteração de senha</h3>
            </div>

            <div className="card-body pb-0">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="password">Digite sua nova senha:</label>
                    <input ref={passwordRef} type="password" name="password" id="password" className="form-control" onChange={handleInputChange} autoComplete="new-password" />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="password_confirmation">Confirme sua nova senha:</label>
                    <input ref={passwordConfirmationRef} type="password" name="password_confirmation" id="password_confirmation" className="form-control" onChange={handleInputChange} autoComplete="new-password" />
                  </div>
                </div>
              </div>
            </div>

            {hasPrivilege && (
              <div className="card-footer d-flex justify-content-end">
                <button type="submit" className="btn btn-primary pe-3 ps-3 btn-sm">Alterar senha</button>
              </div>
            )}
          </div>
        )}
      </form>
    </>
  )
}

export default User
