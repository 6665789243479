import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import App from '@layouts/App/App'
import AuthContext, { AuthProvider } from '@contexts/Auth'
import Auth from '@layouts/Auth/Auth'
import Login from '@screens/Auth/Login/Login'
import ForgotPassword from '@screens/Auth/ForgotPassword/ForgotPassword'
import ResetPassword from '@screens/Auth/ResetPassword/ResetPassword'
import { PanelProvider } from '@contexts/Panel'
import SecurityCode from '@screens/Auth/SecurityCode/SecurityCode'
import { QueryClient, QueryClientProvider } from 'react-query'
import Documents from '@screens/Documents/Documents'
import Notes from '@screens/Notes/Notes'
import Users from '@screens/Users/Users'
import User from '@screens/Users/User/User'
import { LoginProvider } from '@contexts/Login'
import Register from '@screens/Auth/Register/Register'
import Opportunities from '@screens/Opportunities/Opportunities'

const RequireAuth: React.FC<any> = () => {
  const { isLoading, isAuthenticated } = useContext(AuthContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate('/login')
    }
  }, [isAuthenticated, isLoading, navigate])

  if (!isAuthenticated) return null

  return <Outlet />
}

const Router: React.FC<any> = () => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthProvider />}>
            <Route element={<RequireAuth />}>
              <Route element={<PanelProvider />}>
                <Route element={<App />}>
                  <Route index element={<Notes />} />
                  <Route path="avisos" element={<Notes />} />
                  <Route path="documentos" element={<Documents />} />

                  <Route path="oportunidades" element={<Opportunities />} />
                  <Route path="oportunidades/:id" element={<Opportunities />} />

                  <Route path="usuarios" element={<Users />} />
                  <Route path="usuarios/registrar" element={<User />} />
                  <Route path="usuarios/:id" element={<User />} />
                </Route>
              </Route>
            </Route>

            <Route element={<Auth />}>
              <Route element={<LoginProvider />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="security-code" element={<SecurityCode />} />
              </Route>

              <Route path="recuperar-senha" element={<ForgotPassword />} />
              <Route path="redefinir-senha" element={<ResetPassword />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default Router
