import styled from 'styled-components'

export const Auth = styled.form`
  > .register,
  > .login {
    text-align: center;

    > h5 {
      font-weight: 600;
      margin-bottom: 8px;
      font-size: 1rem;
    }

    > a {
      font-weight: 500;
      font-size: 1.2rem;
      text-decoration: underline;
      color: var(--text-color);

      &:hover {
        color: var(--primary-color);
      }
    }
  }
`
