import React from 'react'
import { Card, Content, Type } from './Note.styled'
import moment, { now } from 'moment'
import { Modal } from 'react-bootstrap'
import { AiOutlineDownload } from 'react-icons/ai'

const types: any = {
  info: 'Informação',
  reminder: 'Lembrete',
  task: 'Tarefa',
  warning: 'Aviso',
  alert: 'Alerta',
  important: 'Importante',
}

const Note: React.FC<any> = ({ title, type, content, documents, expires_at }) => {
  const [ show, setShow ] = React.useState<boolean>(false)

  const handleShowModal = () => {
    setShow(true)
  }

  const expiration = expires_at ? moment(expires_at) : null

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex align-items-center gap-10" closeButton>
          <Modal.Title className="mb-0">{title}</Modal.Title>

          <Type className={`badge bg-${type}`}>
            {types[type]}
          </Type>
        </Modal.Header>

        <Modal.Body className="pb-0">
          {content.length > 0 && (
            <Content dangerouslySetInnerHTML={{ __html: content }} />
          )}

          {content.length > 0 && documents.length > 0 && (
            <hr />
          )}

          {documents.length > 0 && (
            <>
              <h6>Documentos anexados</h6>

              <table className="table table-striped table-hover table-sm">
                <tbody>
                  {documents.map((document: any) => (
                    <tr key={document.id}>
                      <td>
                        <a href={document.file} target="_blank" rel="noreferrer" download>{document.name}</a>
                      </td>

                      <td className="d-flex justify-content-end">
                        <a href={document.file} target="_blank" rel="noreferrer" download>
                          <AiOutlineDownload size={20} />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Card className={`card ${type} mb-2`} onClick={handleShowModal}>
        <div className="card-body pt-2 pb-2">
          <div className="note-info">
            <Type className={`badge bg-${type} mb-1`}>{types[type]}</Type>

            <div className="title">
              {title}
            </div>
          </div>

          {expiration && (
            <div className="text-right">
              <div className="valid_until">
                {expiration.isBefore(now()) ? 'expirado' : 'expira'} {expiration.from(now())}
              </div>

              {documents.length > 0 && (
                <small className="text-muted">
                  {documents.length} documento{documents.length === 1 ? '' : 's'} anexado{documents.length === 1 ? '' : 's'}
                </small>
              )}
            </div>
          )}
        </div>
      </Card>
    </>
  )
}

export default Note
