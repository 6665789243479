import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as $Auth from '@services/Auth'
import { Spinner } from 'react-bootstrap'
import LoginContext from '@contexts/Login'
import { Auth } from './Login.styles'

const Login: React.FC<any> = () => {
  const { email, setEmail, password, setPassword, isLoading, setIsLoading, error, setError } = useContext(LoginContext)

  const navigate = useNavigate()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.securityCode(email, password).then(() => {
      setError(null)
      navigate('/security-code')
    }).catch(e => {
      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar realizar o login. Favor tentar novamente.')
    }).finally(() => setIsLoading(false))
  }

  return (
    <div className="row">
      <div className="col-12 col-md-4 offset-md-4">
        <div className="card">
          <div className="card-header">
            <h1 className="w-100 d-block text-center mb-0">Entre com sua conta</h1>
          </div>

          <div className="card-body">
            {error && (
              <div className="alert alert-danger" role="alert">
                <strong>Atenção!</strong> {error}
              </div>
            )}

            <Auth onSubmit={onSubmit}>
              <div className="form-floating has-validation mb-3">
                <input
                  type="email"
                  className={'form-control' + (error ? ' is-invalid' : '')}
                  id="email"
                  onChange={e => setEmail(e.target.value)}
                  disabled={isLoading}
                  autoComplete="email"
                  required
                />
                <label htmlFor="email">Seu endereço de e-mail</label>
              </div>

              <div className="form-floating has-validation mb-3">
                <input
                  type="password"
                  className={'form-control' + (error ? ' is-invalid' : '')}
                  id="password"
                  onChange={e => setPassword(e.target.value)}
                  disabled={isLoading}
                  autoComplete="current-password"
                  required
                />
                <label htmlFor="password">Senha</label>
              </div>

              <div className="text-center mb-3">
                <Link className="btn btn-link p-0" to="/recuperar-senha">Esqueceu sua senha?</Link>
              </div>

              <button type="submit" className="btn btn-primary w-100 btn-block d-flex align-items-center justify-content-center" disabled={isLoading}>
                <span>Entrar</span> {isLoading && <Spinner animation="border" className="ms-2" size="sm" />}
              </button>

              <hr/>

              <div className="register">
                <h5>Ainda não possui uma conta?</h5>
                <Link to="/register">Cadastre-se aqui</Link>
              </div>
            </Auth>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
