import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import IOpportunity from '@interfaces/IOpportunity'

export const all: QueryFunction<AxiosResponse<IOpportunity[]>> = () => {
  return axios.get('v1/client/opportunities')
}

export const create: MutationFunction<AxiosResponse<any, any>, FormData> = (opportunity) => {
  return axios.post('v1/client/opportunities', opportunity)
}

export const renewal: MutationFunction<AxiosResponse<any, any>, [number, FormData]> = ([id, formData]) => {
  formData.append('_method', 'PUT')
  return axios.post(`v1/client/opportunities/${id}/renewal`, formData)
}
