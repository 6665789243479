import styled from 'styled-components'

export const Card = styled.div`
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  cursor: pointer;

  > .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .valid_until {
      > span {
        font-size: 0.8rem;
      }
    }
  }

  &.info {
    border-color: #17a2b8;

    &:hover {
      box-shadow: 0 0 10px #17a2b85F;
    }
  }

  &.reminder {
    border-color: #db8c4c;

    &:hover {
      box-shadow: 0 0 10px #db8c4c5F;
    }
  }

  &.task {
    border-color: #5bc0de;

    &:hover {
      box-shadow: 0 0 10px #5bc0de5F;
    }
  }

  &.warning {
    border-color: #f8c845;

    &:hover {
      box-shadow: 0 0 10px #f8c8455F;
    }
  }

  &.alert {
    border-color: #fd352e;

    &:hover {
      box-shadow: 0 0 10px #fd352e5F;
    }
  }

  &.important {
    border-color: #f8c845;

    &:hover {
      box-shadow: 0 0 10px #f8c8455F;
    }
  }
`

export const Content = styled.div``

export const Type = styled.div``
