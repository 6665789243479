import axios, { AxiosResponse } from 'axios'
import { QueryFunction } from 'react-query'
import INote from '@interfaces/INote'

export const all: QueryFunction<AxiosResponse<INote[]>> = () => {
  return axios.get('v1/client/notes')
}

export const find: QueryFunction<AxiosResponse<any, any>, [number]> = ({ queryKey: [ id ] }) => {
  return axios.get(`v1/client/notes/${id}`)
}
