import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'
import * as $Opportunity from '@services/Opportunity'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import useOpportunityColumns from '@hooks/useOpportunityColumns'
import Opportunity from './Opportunity/Opportunity'
import IOpportunity from '@interfaces/IOpportunity'
import Action from './Action/Action'

const Opportunities: React.FC<any> = () => {
  const [ opportunity, setOpportunity ] = useState<IOpportunity|null>(null)
  const [ showRegister, setShowRegister ] = useState(false)

  const { isLoading, data: response, refetch } = useQuery('opportunities', $Opportunity.all)

  const columns = useOpportunityColumns({
    onRenewal: (opportunity: IOpportunity) => setOpportunity(opportunity),
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: response?.data || [],
  } as any, useSortBy, usePagination)

  const onHide = () => {
    setOpportunity(null)
    setShowRegister(false)
    refetch()
  }

  return (
    <>
      <Action opportunity={opportunity} show={!!opportunity} onHide={onHide} />

      <Opportunity show={showRegister} onHide={onHide} />

      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1 className="page-title mb-0">Registro de Oportunidades</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Início</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Registro de Oportunidades</li>
            </ol>
          </nav>
        </div>

        <button className="btn btn-outline-primary" onClick={() => setShowRegister(true)}>Registrar Oportunidade</button>
      </div>

      <div className="card mb-3">
        <div className="card-body p-0">
          <div className="table-responsive">
            <Table
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              page={page}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              isLoading={isLoading}
              emptyMessage={(
                <>
                  Você ainda não registrou nenhuma oportunidade de negócio,&nbsp;
                  <button className="btn btn-link p-0" onClick={() => setShowRegister(true)}>clique aqui</button> para registrar uma nova.
                </>
              )}
            />
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <Pagination
            page={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            onClick={gotoPage}
          />
        </div>
      </div>
    </>
  )
}

export default Opportunities
