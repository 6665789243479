import React, { useContext } from 'react'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import useUserColumns from '@hooks/useUserColumns'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { all } from '@services/User'
import AuthContext from '@contexts/Auth'

const Users: React.FC<any> = () => {
  const { user } = useContext(AuthContext)

  const columns = useUserColumns()

  const { isLoading, data: response } = useQuery('users', all)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: response?.data || [],
  } as any, useSortBy, usePagination)

  return (
    <>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1 className="page-title mb-0">Usuários</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Início</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Usuários</li>
            </ol>
          </nav>
        </div>

        {user?.is_owner && (
          <Link to="/usuarios/registrar" className="btn btn-outline-primary pe-3 ps-3">Registrar usuário</Link>
        )}
      </div>

      <div className="card mb-3">
        <div className="card-body p-0">
          <div className="table-responsive">
            <Table
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              page={page}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              isLoading={isLoading}
            />
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <Pagination
            page={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            onClick={gotoPage}
          />
        </div>
      </div>
    </>
  )
}

export default Users
